<template>
  <b-container class="no-gutters bg-white px-2 px-md-3 py-2 py-md-3">
    <b-row class="align-items-center justify-content-between mb-3">
      <b-col>
        <b-input-group>
          <b-form-input
            type="search"
            class="search-bar"
            id="header-search-bar"
            @keyup.enter="handleSearch"
            placeholder="Search Name"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text class="mr-2">
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col class="text-right">
        <b-button class="btn-filter ml-2" @click="toggleFilter()">
          <font-awesome-icon icon="filter" class="text-black d-sm-none" />
          <span class="d-none d-sm-block">
            <font-awesome-icon icon="filter" class="pointer" />
            Filter
          </span>
        </b-button>
        <b-dropdown right text="Actions" class="ml-2 main-color btn-filter">
          <b-dropdown-item @click="modalImport = true"
            >Import Coupon</b-dropdown-item
          >
          <b-dropdown-item @click="exportCode()">Export</b-dropdown-item>
          <b-dropdown-item @click="downloadTemplate()"
            >Download Template</b-dropdown-item
          >
        </b-dropdown>
      </b-col>
    </b-row>
    <b-table
      ref="tableCustomerUseCoupon"
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(used_time)="data">
        <div v-if="$moment(data.item.used_time).year() != '0001'">
          {{ data.item.used_time | moment("DD/MM/YYYY (HH:mm:ss)") }}
        </div>
        <div v-else>-</div>
      </template>
      <template v-slot:cell(created_time)="data">
        <div>
          {{ data.item.created_time | moment("DD/MM/YYYY (HH:mm:ss)") }}
        </div>
      </template>
      <template v-slot:cell(invoice_no)="data">
        <span
          class="text-center text-underline cursor-pointer"
          v-if="data.item.invoice_no"
          @click="
            $router.push('/report/transaction/' + data.item.transaction_id)
          "
          >{{ data.item.invoice_no }}
        </span>
        <span v-else>{{ "-" }}</span>
      </template>
      <template v-slot:cell(status)="data">
        <div
          :class="[
            data.item.status == 'Ready to Use'
              ? 'text-info'
              : data.item.status == 'Expired'
              ? 'text-error'
              : 'text-success',
          ]"
        >
          {{ data.item.status }}
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <SideBarFilter
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mt-3">
          <InputDatePickerFilter
            textFloat="Created Date"
            name="create-date"
            ref="create-date"
            :value="filter.created_time"
            :max-datetime="$moment().format()"
            @input="(val) => (filter.created_time = val)"
          />
        </div>
        <div class="mt-3">
          <InputDatePickerFilter
            textFloat="Used Date"
            name="date-from"
            ref="date-from"
            :value="filter.used_time"
            :max-datetime="$moment().format()"
            @input="(val) => (filter.used_time = val)"
          />
        </div>
        <div class="mt-3">
          <b-form-group label="Status">
            <b-form-checkbox-group
              v-model="filter.status"
              :options="statusList"
              value-field="id"
              text-field="name"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </template>
    </SideBarFilter>
    <b-modal
      v-model="modalImport"
      no-close-on-backdrop
      centered
      title="Import Coupon"
      hide-footer
      @hidden="deleteFile"
    >
      <div>
        <b-row class="no-gutters">
          <b-col md="12">
            <UploadFileV2
              textFloat="File"
              placeholder="Please Choose File"
              format="excel"
              name="file"
              text="*Please upload only file .xlsx less than 10 MB"
              isRequired
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              :fileName="formExcel.file_name"
              v-model="formExcel.coupon_code_import"
              :v="$v.formExcel.coupon_code_import"
              id="uploadfile"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button size="md" class="btn-cancel" @click="modalImport = false"
              >Cancel</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importFiles"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </b-container>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";

import { required } from "vuelidate/lib/validators";
export default {
  components: { UploadFileV2 },
  props: {
    form: {
      required: false,
    },
  },
  validations() {
    return {
      formExcel: {
        coupon_code_import: {
          required,
        },
      },
    };
  },
  data() {
    return {
      isDisable: false,
      statusList: [
        { name: "Ready to use", id: 0 },
        { name: "Used", id: 1 },
        { name: "Expired", id: 2 },
      ],
      filter: {
        search: "",
        created_time: "",
        used_time: "",
        status: null,
        take: 10,
        page: 1,
      },
      rows: 0,
      fields: [
        { key: "coupon_code", label: "Coupon Code" },
        { key: "created_time", label: "Date / Time Created" },
        { key: "used_time", label: "Date / Time Used" },
        { key: "member_id", label: "Customer" },
        { key: "invoice_no", label: "Invoice No." },
        { key: "status", label: "status" },
        { key: "action", label: "" },
      ],
      items: [],
      isBusy: false,
      formExcel: { coupon_code_import: "", file_name: "" },
      modalImport: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.used_time = payload.used_time ? payload.used_time : "0001-01-01";
      payload.created_time = payload.created_time
        ? payload.created_time
        : "0001-01-01";
      const res = await this.axios.post(
        `/coupon/CouponCodeList/${this.form.promotion_coupon_id}`,
        payload
      );
      this.items = res.data.detail.detail;
      this.rows = res.data.detail.count;
      this.isBusy = false;
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.getData();
    },
    pagination(page) {
      this.filter.page = page;
      this.getData();
    },
    handleSearch() {
      this.getData();
    },
    async exportCode() {
      try {
        this.$bus.$emit("showLoading");
        let payload = { ...this.filter };
        payload.used_time = payload.used_time
          ? payload.used_time
          : "0001-01-01";
        payload.created_time = payload.created_time
          ? payload.created_time
          : "0001-01-01";
        const res = await this.$report.post(
          `/dashboard/exportcouponcode/${this.form.promotion_coupon_id}`,
          payload
        );
        this.$bus.$emit("hideLoading");
        this.confirmAlert({
          message: "Do you want to download the file now?",
          title: "Success !",
          icon: "success",
          confirmButtonText: "Go To Page",
          cancelButtonText: "Close",
        }).then((val) => {
          if (val.isConfirmed) {
            // this.deleteItem(id);
            const routeData = this.$router.resolve({
              name: "Customer Report List",
            });
            window.open(routeData.href, "_blank");
          }
        });
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error);
      }
    },
    async downloadTemplate() {
      const res = await this.axios(
        `/coupon/getcouponcodetemplate`,

        {
          responseType: "blob",
        }
      );
      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "coupon-code-template.xlsx");
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    filterActions() {
      if (this.filter.used_time)
        this.filter.used_time = this.$moment(this.filter.used_time).format(
          "YYYY-MM-DD"
        );

      this.getData();
    },
    clearFilter() {},
    onFileChange(file) {
      this.formExcel.file_name = file.name;
      this.isDisable = true;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formExcel.coupon_code_import = reader.result.substring(
          str.length,
          reader.result.length
        );
        this.isDisable = false;
      };
    },
    deleteFile(value) {
      this.formExcel = {
        file_name: "",
        coupon_code_import: "",
      };
    },
    toggleFilter() {
      this.$refs.filter.show();
    },
    async importFiles() {
      this.$v.formExcel.$touch();
      if (this.$v.formExcel.$error) {
        return;
      }
      const result = await this.axios.post(
        `/coupon/ImportCouponCode/${this.form.promotion_coupon_id}`,
        this.formExcel
      );
      if (result.data.result) {
        this.successAlert();
      } else {
        this.errorAlert(result.data.message);
      }
    },
  },
};
</script>

<style></style>
